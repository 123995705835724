import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { fetchLogin } from "../redux/slices/auth";
import { Context } from "../Context";

export const ActivateAccount = () => {
  const [activate, setActivate] = useState(false);
  const { setUserData, setLinks } = useContext(Context);
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const dispatch = useDispatch();
  const fetchDataUser = async () => {
    const data = await dispatch(fetchLogin());

    if (data.payload) {
      const { email, prompts, links, paymentHistory, activation } =
        data.payload;
      setUserData({ email, prompts, paymentHistory, activation });
      setLinks([...links]);
    }
  };
  if (!token) {
    <Navigate to="/" />;
  }
  const getActivate = async () => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_LINK_API_BACKEND}activateAccount`,
      {
        token,
      }
    );
    if (data.msg === "Invalid or expired recovery token") {
      setActivate(false);
    }
    if (data.msg === "Your account is activated") {
      setActivate(true);
    }
    fetchDataUser();
  };
  useEffect(() => {
    getActivate();
  }, []);
  return (
    <>
      <div
        className="relative z-10 "
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 transition-opacity"></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-gray-800 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div
                    className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
                      activate ? "bg-green-600" : "bg-red-100"
                    } sm:mx-0 sm:h-10 sm:w-10`}
                  >
                    {activate ? (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 12.75L11.25 15L15 9.75M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="h-6 w-6 text-red-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                        ></path>
                      </svg>
                    )}
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3
                      className="text-base font-semibold leading-6  text-white"
                      id="modal-title"
                    >
                      {activate ? "Your account is activated" : "Error"}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-300">
                        {activate
                          ? "Congratulations, you have activated your account."
                          : "This link is no longer active"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-800 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <Link
                  to="/Dashboard/Profile"
                  className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto mb-5"
                >
                  Good
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { useState } from "react";

const imgs = ["img/register.png", "img/prompt.png", "img/result.png"];
export const Info = () => {
  const [activeImg, setActiveImg] = useState(0);
  return (
    <>
      <section id="generate" className="info">
        <h1 className="text-center">How to generate AI images</h1>
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div className="relative p-6 md:p-16">
            <div className="relative z-10 lg:grid lg:grid-cols-12 lg:gap-16 lg:items-center">
              <div className="mb-10 lg:mb-0 lg:col-span-6 lg:col-start-8 lg:order-2">
                <h2 className="text-2xl  font-bold sm:text-3xl text-gray-200"></h2>

                <nav
                  className="grid gap-4 mt-5 md:mt-10"
                  aria-label="Tabs"
                  role="tablist"
                >
                  <button
                    onClick={() => setActiveImg(0)}
                    className="hs-tab-active:ring-gray-600 text-start  p-4 md:p-5 rounded-xl dark:focus:outline-none focus:ring-1 focus:ring-gray-600"
                    id="tabs-with-card-item-1"
                    data-hs-tab="#tabs-with-card-1"
                    aria-controls="tabs-with-card-1"
                    role="tab"
                  >
                    <span className="flex items-center">
                      <svg
                        className=""
                        width="60"
                        viewBox="0 0 44 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M35.295 36.9443C33.7422 34.8885 31.7332 33.2212 29.4264 32.074C27.1195 30.9267 24.5778 30.3308 22.0014 30.3331C19.425 30.3308 16.8833 30.9267 14.5765 32.074C12.2696 33.2212 10.2607 34.8885 8.70789 36.9443M35.295 36.9443C38.325 34.2492 40.462 30.6966 41.4266 26.7577C42.3912 22.8188 42.1357 18.6798 40.6939 14.8895C39.2521 11.0992 36.6922 7.83672 33.3536 5.53473C30.015 3.23274 26.0556 2 22.0003 2C17.9451 2 13.9856 3.23274 10.647 5.53473C7.30848 7.83672 4.74856 11.0992 3.30677 14.8895C1.86498 18.6798 1.60944 22.8188 2.57405 26.7577C3.53866 30.6966 5.67784 34.2492 8.70789 36.9443M35.295 36.9443C31.6372 40.207 26.9029 42.007 22.0014 42C17.0992 42.0075 12.3662 40.2075 8.70789 36.9443M28.6682 16.9996C28.6682 18.7677 27.9658 20.4634 26.7156 21.7137C25.4653 22.964 23.7696 23.6664 22.0014 23.6664C20.2333 23.6664 18.5376 22.964 17.2873 21.7137C16.037 20.4634 15.3347 18.7677 15.3347 16.9996C15.3347 15.2314 16.037 13.5357 17.2873 12.2855C18.5376 11.0352 20.2333 10.3328 22.0014 10.3328C23.7696 10.3328 25.4653 11.0352 26.7156 12.2855C27.9658 13.5357 28.6682 15.2314 28.6682 16.9996Z"
                          stroke="white"
                          stroke-width="3.33339"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <span className="grow ms-6">
                        <span className="block text-lg font-semibold text-blue-500">
                          Register
                        </span>
                        <span className="block mt-1  dark:hs-tab-active:text-gray-200 text-gray-200">
                          Register on our website and you will automatically be
                          redirected to the generation page.
                        </span>
                      </span>
                    </span>
                  </button>

                  <button
                    onClick={() => setActiveImg(1)}
                    className="hs-tab-active:bg-white hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start  p-4 md:p-5 rounded-xl dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    id="tabs-with-card-item-2"
                    data-hs-tab="#tabs-with-card-2"
                    aria-controls="tabs-with-card-2"
                    role="tab"
                  >
                    <span className="flex items-center">
                      <svg
                        width="32"
                        viewBox="0 0 32 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 8.75V25C1 25.9946 1.39509 26.9484 2.09835 27.6516C2.80161 28.3549 3.75544 28.75 4.75 28.75H27.25C28.2446 28.75 29.1984 28.3549 29.9016 27.6516C30.6049 26.9484 31 25.9946 31 25V8.75M1 8.75V5C1 4.00544 1.39509 3.05161 2.09835 2.34835C2.80161 1.64509 3.75544 1.25 4.75 1.25H27.25C28.2446 1.25 29.1984 1.64509 29.9016 2.34835C30.6049 3.05161 31 4.00544 31 5V8.75M1 8.75H31M4.75 5H4.76333V5.01333H4.75V5ZM8.5 5H8.51333V5.01333H8.5V5ZM12.25 5H12.2633V5.01333H12.25V5Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <span className="grow ms-6">
                        <span className="block text-lg font-semibold text-blue-500">
                          Enter propmt
                        </span>
                        <span className="block mt-1  dark:hs-tab-active:text-gray-200 text-gray-200">
                          Enter your propmt and wait for the result
                        </span>
                      </span>
                    </span>
                  </button>

                  <button
                    onClick={() => setActiveImg(2)}
                    className="hs-tab-active:bg-white hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start p-4 md:p-5 rounded-xl  dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    id="tabs-with-card-item-3"
                    data-hs-tab="#tabs-with-card-3"
                    aria-controls="tabs-with-card-3"
                    role="tab"
                  >
                    <span className="flex items-center">
                      <svg
                        className="flex-shrink-0 mt-2 h-8 w-8 md:w-7 md:h-7 hs-tab-active:text-blue-600  dark:hs-tab-active:text-blue-500 text-gray-200"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
                        <path d="M5 3v4" />
                        <path d="M19 17v4" />
                        <path d="M3 5h4" />
                        <path d="M17 19h4" />
                      </svg>
                      <span className="grow ms-6">
                        <span className="block text-lg font-semibold text-blue-500">
                          Result
                        </span>
                        <span className="block mt-1  dark:hs-tab-active:text-gray-200 text-gray-200">
                          Enjoy the result, you can download the generated
                          image, add it to your collection, generate a new image
                        </span>
                      </span>
                    </span>
                  </button>
                </nav>
              </div>

              <div className="lg:col-span-6">
                <div className="relative">
                  <div>
                    <div
                      id="tabs-with-card-1"
                      role="tabpanel"
                      aria-labelledby="tabs-with-card-item-1"
                    >
                      <img
                        className="shadow-xl shadow-gray-200 rounded-xl dark:shadow-gray-900/[.2]"
                        src={imgs[activeImg]}
                        alt="Image Description"
                      />
                    </div>
                  </div>

                  <div className="hidden absolute top-0 end-0 translate-x-20 md:block lg:translate-x-20">
                    <svg
                      width="121"
                      height="135"
                      viewBox="0 0 121 135"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_23_2)">
                        <path
                          d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164"
                          stroke="#4467FF"
                          stroke-width="10"
                          stroke-linecap="round"
                        />
                        <path
                          d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5"
                          stroke="url(#paint0_linear_23_2)"
                          stroke-width="10"
                          stroke-linecap="round"
                        />
                        <path
                          d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874"
                          stroke="url(#paint1_linear_23_2)"
                          stroke-width="10"
                          stroke-linecap="round"
                        />
                      </g>
                      <defs>
                        <linearGradient
                          id="paint0_linear_23_2"
                          x1="58.5791"
                          y1="5"
                          x2="-243.5"
                          y2="59"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#4467FF" />
                          <stop offset="0.997521" stop-opacity="0" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_23_2"
                          x1="83.2762"
                          y1="78.0874"
                          x2="14.5"
                          y2="114"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop offset="0.732296" stop-color="#4467FF" />
                          <stop offset="1" stop-opacity="0" />
                        </linearGradient>
                        <clipPath id="clip0_23_2">
                          <rect width="121" height="135" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className="absolute inset-0 grid grid-cols-12 w-full h-full">
              <div className="col-span-full lg:col-span-7 lg:col-start-6 w-full h-5/6 rounded-xl sm:h-3/4 lg:h-full bg-white/[.075]"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

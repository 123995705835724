import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/slices/auth";
import { useDispatch } from "react-redux";
import { Context } from "../Context";
import Cookies from "js-cookie";

export const NavBar = () => {
  const dispatch = useDispatch();
  const [openNav, setOpenNav] = React.useState(true);
  const { userData } = React.useContext(Context);
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout());
    let cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let eqPos = cookie.indexOf("=");
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
      document.cookie =
        name + "=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
    Cookies.remove("webToken");
    navigate("/Login");
  };
  return (
    <div className="antialiased">
      <nav className="border-b bg-gray-800 px-4 py-2.5 border-gray-700 fixed left-0 right-0 top-0 z-50">
        <div className="flex  justify-between items-center">
          <div className="flex justify-start items-center">
            <a
              onClick={() => setOpenNav(!openNav)}
              data-drawer-target="drawer-navigation"
              data-drawer-toggle="drawer-navigation"
              aria-controls="drawer-navigation"
              className="p-2 mr-2  rounded-lg cursor-pointer md:hidden hover:text-gray-900  focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 focus:ring-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white"
            >
              <svg
                aria-hidden="true"
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <svg
                aria-hidden="true"
                className="hidden w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Toggle sidebar</span>
            </a>
            <a
              href="https://flowbite.com"
              className="flex items-center justify-between mr-4"
            >
              <Link to="/" className="logo flex uppercase text-lg">
                FlxArt
              </Link>
            </a>
          </div>
          <div className="flex items-center lg:order-2">
            <div className="flex items-center">
              <Link
                className="mr-4 text-white hover:underline"
                to="/dashboard/profile"
                onClick={() => setOpenNav(true)}
              >
                {userData.email}
              </Link>
              <a onClick={handleLogout} className="btn-logout text-white">
                Logout
              </a>
            </div>
          </div>
        </div>
      </nav>

      <aside
        className={`fixed top-0 left-0 z-40 w-64 h-screen pt-14 transition-transform ${
          openNav && "-translate-x-full"
        }  border-r  md:translate-x-0 bg-gray-800 border-gray-700`}
      >
        <div className="overflow-y-auto py-5 px-3 h-full bg-gray-800">
          <ul className="space-y-2">
            <li>
              <Link
                onClick={() => setOpenNav(!openNav)}
                to="Profile"
                className="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 hover:bg-gray-700 text-white group"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#9ca3af"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="flex-shrink-0 w-7 h-7 transition duration-75 text-gray-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                  />
                </svg>

                <span className="ml-3">Profile</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setOpenNav(!openNav)}
                to="generation"
                className="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 hover:bg-gray-700 text-white group"
              >
                <svg
                  className="flex-shrink-0 w-6 h-6 transition duration-75 text-gray-400 group-hover:text-gray-900 group-hover:text-white"
                  viewBox="0 0 40 40"
                  fill="#9ca3af"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.9905 27.1573L14.5 32.375L13.0095 27.1573C12.6243 25.8096 11.902 24.5822 10.9108 23.591C9.91963 22.5999 8.69226 21.8776 7.3445 21.4923L2.125 20L7.34267 18.5095C8.69042 18.1243 9.9178 17.402 10.909 16.4108C11.9001 15.4196 12.6224 14.1923 13.0077 12.8445L14.5 7.625L15.9905 12.8427C16.3757 14.1904 17.098 15.4178 18.0892 16.409C19.0804 17.4001 20.3077 18.1224 21.6555 18.5077L26.875 20L21.6573 21.4905C20.3096 21.8757 19.0822 22.598 18.091 23.5892C17.0999 24.5804 16.3776 25.8077 15.9923 27.1555L15.9905 27.1573ZM31.4748 13.9775L31 15.875L30.5252 13.9775C30.2534 12.8896 29.691 11.896 28.8983 11.1029C28.1055 10.3098 27.1121 9.74707 26.0243 9.47483L24.125 9L26.0243 8.52517C27.1121 8.25293 28.1055 7.69019 28.8983 6.89711C29.691 6.10403 30.2534 5.11042 30.5252 4.0225L31 2.125L31.4748 4.0225C31.7468 5.11065 32.3094 6.10441 33.1025 6.89751C33.8956 7.69061 34.8894 8.25322 35.9775 8.52517L37.875 9L35.9775 9.47483C34.8894 9.74678 33.8956 10.3094 33.1025 11.1025C32.3094 11.8956 31.7468 12.8894 31.4748 13.9775ZM28.9723 35.7062L28.25 37.875L27.5277 35.7062C27.3252 35.0987 26.984 34.5466 26.5312 34.0938C26.0784 33.641 25.5263 33.2998 24.9188 33.0973L22.75 32.375L24.9188 31.6527C25.5263 31.4502 26.0784 31.109 26.5312 30.6562C26.984 30.2034 27.3252 29.6513 27.5277 29.0438L28.25 26.875L28.9723 29.0438C29.1748 29.6513 29.516 30.2034 29.9688 30.6562C30.4216 31.109 30.9737 31.4502 31.5812 31.6527L33.75 32.375L31.5812 33.0973C30.9737 33.2998 30.4216 33.641 29.9688 34.0938C29.516 34.5466 29.1748 35.0987 28.9723 35.7062Z"
                    stroke="#9CA3AE"
                    stroke-width="2.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span className="ml-3">Generation</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setOpenNav(!openNav)}
                to="collection"
                className="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 hover:bg-gray-700 text-white group"
              >
                <svg
                  aria-hidden="true"
                  className="flex-shrink-0 w-6 h-6 transition duration-75 text-gray-400 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>
                </svg>
                <span className="ml-3">Collection</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setOpenNav(!openNav)}
                to="TopUp"
                className="flex items-center p-2 text-base font-medium rounded-lg transition duration-75 hover:bg-gray-700 text-white group"
              >
                <svg
                  className="w-6 h-6"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 4V16M7 13.182L7.879 13.841C9.05 14.72 10.949 14.72 12.121 13.841C13.293 12.962 13.293 11.538 12.121 10.659C11.536 10.219 10.768 10 10 10C9.275 10 8.55 9.78 7.997 9.341C6.891 8.462 6.891 7.038 7.997 6.159C9.103 5.28 10.897 5.28 12.003 6.159L12.418 6.489M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
                    stroke="#9CA3AF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span className="ml-3">Top-up</span>
              </Link>
            </li>
          </ul>
          <ul className="pt-5 mt-5 space-y-2 border-t border-gray-700">
            <li>
              <Link
                onClick={() => setOpenNav(!openNav)}
                to="transactions"
                className="flex items-center p-2 text-base font-medium rounded-lg transition duration-75 hover:bg-gray-700 text-white group"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 flex-shrink-0 transition duration-75 text-gray-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                  />
                </svg>

                <span className="ml-3">Transactions</span>
              </Link>
              <Link
                onClick={() => setOpenNav(!openNav)}
                to="support"
                className="flex items-center p-2 text-base font-medium rounded-lg transition duration-75 hover:bg-gray-700 text-white group"
              >
                <svg
                  aria-hidden="true"
                  className="flex-shrink-0 w-6 h-6  transition duration-75 text-gray-400  "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="ml-3">Help</span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  );
};

import React, { useContext } from "react";
import SideBar from "./SideBar";
import { Link } from "react-router-dom";
import "animate.css";
import { isAuthSelector, logout } from "../redux/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import { Context } from "../Context";
import Cookies from "js-cookie";
import { motion } from "framer-motion";

export function Header() {
  const dispatch = useDispatch();
  const { viewBar, setViewBar } = useContext(Context);
  const checkAuth = useSelector(isAuthSelector);
  const onClickLogout = () => {
    dispatch(logout());
    Cookies.remove("webToken");
    let cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let eqPos = cookie.indexOf("=");
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
      document.cookie =
        name + "=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
    window.location.reload();
  };
  const textAnimation = {
    hidden: {
      x: 100,
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 0.1 },
    }),
  };
  return (
    <>
      <header initial="hidden" whileInView="visible" className="header p-5">
        <motion.nav
          initial="hidden"
          viewport={{ once: true }}
          whileInView="visible"
          className="flex justify-between items-center"
        >
          <motion.div custom={1} variants={textAnimation}>
            <Link
              custom={1}
              variants={textAnimation}
              to="/"
              className="logo flex uppercase text-lg"
            >
              FlxArt
            </Link>
          </motion.div>
          <motion.div
            custom={2}
            variants={textAnimation}
            className="btns space-x-4 md"
          >
            <a href="#generate">Generate</a>
            <a href="#pricing">Pricing</a>
            <a href="#collection">Collection</a>
          </motion.div>
          <motion.div
            custom={3}
            variants={textAnimation}
            className="sign-block flex space-x-4"
          >
            {checkAuth ? (
              <div className="flex items-center">
                <Link className="mr-4 hover:underline" to="/dashboard/profile">
                  My Account
                </Link>
                <a onClick={onClickLogout} className="btn-logout text-white">
                  Logout
                </a>
              </div>
            ) : (
              <>
                <Link to="/Login" className="btn-sign in">
                  Sign In
                </Link>
                <Link to="/SignUp" className="btn-sign up">
                  Sign Up
                </Link>
              </>
            )}
          </motion.div>
          <motion.div
            variants={textAnimation}
            onClick={() => setViewBar(!viewBar)}
            className="burger"
          >
            <div className="burger-block"></div>
            <div className="burger-block"></div>
            <div className="burger-block"></div>
          </motion.div>
        </motion.nav>
        <SideBar />
      </header>
    </>
  );
}
